import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9bdf3270 = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _d3d64e50 = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _05bde6d9 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _36992b46 = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _531c7410 = () => interopDefault(import('../pages/indeks/index.vue' /* webpackChunkName: "pages/indeks/index" */))
const _5727d214 = () => interopDefault(import('../pages/interest/index.vue' /* webpackChunkName: "pages/interest/index" */))
const _d21c5a1e = () => interopDefault(import('../pages/karir/index.vue' /* webpackChunkName: "pages/karir/index" */))
const _5bfd90d6 = () => interopDefault(import('../pages/live/index.vue' /* webpackChunkName: "pages/live/index" */))
const _52ce0b7f = () => interopDefault(import('../pages/matanajwasurakarta.vue' /* webpackChunkName: "pages/matanajwasurakarta" */))
const _48eb6d90 = () => interopDefault(import('../pages/pilihan-narasi/index.vue' /* webpackChunkName: "pages/pilihan-narasi/index" */))
const _466d8b20 = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _7520ac7c = () => interopDefault(import('../pages/religi/index.vue' /* webpackChunkName: "pages/religi/index" */))
const _511ad2d2 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _1bb39abf = () => interopDefault(import('../pages/suarapenentu/index.vue' /* webpackChunkName: "pages/suarapenentu/index" */))
const _d3a4970a = () => interopDefault(import('../pages/terpopuler.vue' /* webpackChunkName: "pages/terpopuler" */))
const _4f885613 = () => interopDefault(import('../pages/academy/frequently-asked-questions/index.vue' /* webpackChunkName: "pages/academy/frequently-asked-questions/index" */))
const _423387e6 = () => interopDefault(import('../pages/academy/kelas/index.vue' /* webpackChunkName: "pages/academy/kelas/index" */))
const _e3a2bf9c = () => interopDefault(import('../pages/account/event-saya.vue' /* webpackChunkName: "pages/account/event-saya" */))
const _96ba27f0 = () => interopDefault(import('../pages/account/kelas-saya.vue' /* webpackChunkName: "pages/account/kelas-saya" */))
const _183a04d8 = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _6e42d104 = () => interopDefault(import('../pages/auth/complete-data.vue' /* webpackChunkName: "pages/auth/complete-data" */))
const _1c5b2845 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _ccf2ed28 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _061f32d6 = () => interopDefault(import('../pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _8d57a586 = () => interopDefault(import('../pages/event/category/index.vue' /* webpackChunkName: "pages/event/category/index" */))
const _0edb5dd6 = () => interopDefault(import('../pages/event/linimasa-event-narasi/index.vue' /* webpackChunkName: "pages/event/linimasa-event-narasi/index" */))
const _b5a00d0a = () => interopDefault(import('../pages/interest/untukmu.vue' /* webpackChunkName: "pages/interest/untukmu" */))
const _eb6c3054 = () => interopDefault(import('../pages/read/terpopuler.vue' /* webpackChunkName: "pages/read/terpopuler" */))
const _f5653b4e = () => interopDefault(import('../pages/religi/artikel/index.vue' /* webpackChunkName: "pages/religi/artikel/index" */))
const _7a14501a = () => interopDefault(import('../pages/religi/quran-digital/index.vue' /* webpackChunkName: "pages/religi/quran-digital/index" */))
const _f91654f2 = () => interopDefault(import('../pages/suarapenentu/iframe.vue' /* webpackChunkName: "pages/suarapenentu/iframe" */))
const _1b736648 = () => interopDefault(import('../pages/suarapenentu/quick-count.vue' /* webpackChunkName: "pages/suarapenentu/quick-count" */))
const _2d61141b = () => interopDefault(import('../pages/suarapenentu/rekamjejak/index.vue' /* webpackChunkName: "pages/suarapenentu/rekamjejak/index" */))
const _1e87dd27 = () => interopDefault(import('../pages/suarapenentu/widget.vue' /* webpackChunkName: "pages/suarapenentu/widget" */))
const _4f7be54f = () => interopDefault(import('../pages/video/terpopuler.vue' /* webpackChunkName: "pages/video/terpopuler" */))
const _2d3bf701 = () => interopDefault(import('../pages/auth/register/with-email.vue' /* webpackChunkName: "pages/auth/register/with-email" */))
const _264da91e = () => interopDefault(import('../pages/academy/complete/_slug.vue' /* webpackChunkName: "pages/academy/complete/_slug" */))
const _e5c88294 = () => interopDefault(import('../pages/academy/detail/_slug.vue' /* webpackChunkName: "pages/academy/detail/_slug" */))
const _609ac004 = () => interopDefault(import('../pages/academy/karya-academy/_slug.vue' /* webpackChunkName: "pages/academy/karya-academy/_slug" */))
const _e16d43b4 = () => interopDefault(import('../pages/academy/mentor/_slug.vue' /* webpackChunkName: "pages/academy/mentor/_slug" */))
const _1248a066 = () => interopDefault(import('../pages/event/linimasa-event-narasi/_slug.vue' /* webpackChunkName: "pages/event/linimasa-event-narasi/_slug" */))
const _9ee8c43c = () => interopDefault(import('../pages/religi/jadwal-sholat/_slug.vue' /* webpackChunkName: "pages/religi/jadwal-sholat/_slug" */))
const _dfd65e24 = () => interopDefault(import('../pages/religi/quran-digital/_slug/index.vue' /* webpackChunkName: "pages/religi/quran-digital/_slug/index" */))
const _2baa72d3 = () => interopDefault(import('../pages/suarapenentu/rekamjejak/_slug.vue' /* webpackChunkName: "pages/suarapenentu/rekamjejak/_slug" */))
const _16d5acd3 = () => interopDefault(import('../pages/read/advertorial/_channel/_slug.vue' /* webpackChunkName: "pages/read/advertorial/_channel/_slug" */))
const _37cd08e1 = () => interopDefault(import('../pages/religi/quran-digital/_slug/_idAyat.vue' /* webpackChunkName: "pages/religi/quran-digital/_slug/_idAyat" */))
const _5ab7984c = () => interopDefault(import('../pages/video/advertorial/_channel/_slug.vue' /* webpackChunkName: "pages/video/advertorial/_channel/_slug" */))
const _d74390e0 = () => interopDefault(import('../pages/academy/_slug.vue' /* webpackChunkName: "pages/academy/_slug" */))
const _4df34e6f = () => interopDefault(import('../pages/event/_organizer/index.vue' /* webpackChunkName: "pages/event/_organizer/index" */))
const _4d575f29 = () => interopDefault(import('../pages/invite/_token.vue' /* webpackChunkName: "pages/invite/_token" */))
const _d5899cae = () => interopDefault(import('../pages/karir/_slug.vue' /* webpackChunkName: "pages/karir/_slug" */))
const _5a46ef8e = () => interopDefault(import('../pages/live/_slug.vue' /* webpackChunkName: "pages/live/_slug" */))
const _162daba7 = () => interopDefault(import('../pages/polling/_slug.vue' /* webpackChunkName: "pages/polling/_slug" */))
const _24a3770b = () => interopDefault(import('../pages/program/_childBrand/index.vue' /* webpackChunkName: "pages/program/_childBrand/index" */))
const _96136890 = () => interopDefault(import('../pages/read/_slug.vue' /* webpackChunkName: "pages/read/_slug" */))
const _b393c30a = () => interopDefault(import('../pages/tags/_slug.vue' /* webpackChunkName: "pages/tags/_slug" */))
const _2fb30b2c = () => interopDefault(import('../pages/event/_organizer/_slug/index.vue' /* webpackChunkName: "pages/event/_organizer/_slug/index" */))
const _51de9d3f = () => interopDefault(import('../pages/playlist/_category/_channel/index.vue' /* webpackChunkName: "pages/playlist/_category/_channel/index" */))
const _8c41847c = () => interopDefault(import('../pages/playlist/_category/_slug.vue' /* webpackChunkName: "pages/playlist/_category/_slug" */))
const _08b25ed1 = () => interopDefault(import('../pages/podcast/_channel/_slug.vue' /* webpackChunkName: "pages/podcast/_channel/_slug" */))
const _0818c51e = () => interopDefault(import('../pages/program/_childBrand/_slugProgram.vue' /* webpackChunkName: "pages/program/_childBrand/_slugProgram" */))
const _584242c1 = () => interopDefault(import('../pages/read/_channel/_slug.vue' /* webpackChunkName: "pages/read/_channel/_slug" */))
const _10d6dbba = () => interopDefault(import('../pages/video/_channel/_slug.vue' /* webpackChunkName: "pages/video/_channel/_slug" */))
const _47b4d644 = () => interopDefault(import('../pages/event/_organizer/_slug/checkout.vue' /* webpackChunkName: "pages/event/_organizer/_slug/checkout" */))
const _6ad6761c = () => interopDefault(import('../pages/event/_organizer/_slug/survey.vue' /* webpackChunkName: "pages/event/_organizer/_slug/survey" */))
const _6f4c5fa4 = () => interopDefault(import('../pages/event/_organizer/_slug/ticket.vue' /* webpackChunkName: "pages/event/_organizer/_slug/ticket" */))
const _5027fbf7 = () => interopDefault(import('../pages/playlist/_category/_channel/_slug.vue' /* webpackChunkName: "pages/playlist/_category/_channel/_slug" */))
const _1d4b0d3b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _418b9ec9 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _1b946bf3 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _3fd4fd81 = () => interopDefault(import('../pages/_category/_slug.vue' /* webpackChunkName: "pages/_category/_slug" */))
const _3c03b75c = () => interopDefault(import('../pages/_category/_slug.vue/index.vue' /* webpackChunkName: "pages/_category/_slug.vue/index" */))
const _03159634 = () => interopDefault(import('../pages/_channel/_slug.vue' /* webpackChunkName: "pages/_channel/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _9bdf3270,
    name: "about-us"
  }, {
    path: "/academy",
    component: _d3d64e50,
    name: "academy"
  }, {
    path: "/account",
    component: _05bde6d9,
    name: "account"
  }, {
    path: "/event",
    component: _36992b46,
    name: "event"
  }, {
    path: "/indeks",
    component: _531c7410,
    name: "indeks"
  }, {
    path: "/interest",
    component: _5727d214,
    name: "interest"
  }, {
    path: "/karir",
    component: _d21c5a1e,
    name: "karir"
  }, {
    path: "/live",
    component: _5bfd90d6,
    name: "live"
  }, {
    path: "/matanajwasurakarta",
    component: _52ce0b7f,
    name: "matanajwasurakarta"
  }, {
    path: "/pilihan-narasi",
    component: _48eb6d90,
    name: "pilihan-narasi"
  }, {
    path: "/program",
    component: _466d8b20,
    name: "program"
  }, {
    path: "/religi",
    component: _7520ac7c,
    name: "religi"
  }, {
    path: "/search",
    component: _511ad2d2,
    name: "search"
  }, {
    path: "/suarapenentu",
    component: _1bb39abf,
    name: "suarapenentu"
  }, {
    path: "/terpopuler",
    component: _d3a4970a,
    name: "terpopuler"
  }, {
    path: "/academy/frequently-asked-questions",
    component: _4f885613,
    name: "academy-frequently-asked-questions"
  }, {
    path: "/academy/kelas",
    component: _423387e6,
    name: "academy-kelas"
  }, {
    path: "/account/event-saya",
    component: _e3a2bf9c,
    name: "account-event-saya"
  }, {
    path: "/account/kelas-saya",
    component: _96ba27f0,
    name: "account-kelas-saya"
  }, {
    path: "/auth/change-password",
    component: _183a04d8,
    name: "auth-change-password"
  }, {
    path: "/auth/complete-data",
    component: _6e42d104,
    name: "auth-complete-data"
  }, {
    path: "/auth/forgot-password",
    component: _1c5b2845,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _ccf2ed28,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _061f32d6,
    name: "auth-register"
  }, {
    path: "/event/category",
    component: _8d57a586,
    name: "event-category"
  }, {
    path: "/event/linimasa-event-narasi",
    component: _0edb5dd6,
    name: "event-linimasa-event-narasi"
  }, {
    path: "/interest/untukmu",
    component: _b5a00d0a,
    name: "interest-untukmu"
  }, {
    path: "/read/terpopuler",
    component: _eb6c3054,
    name: "read-terpopuler"
  }, {
    path: "/religi/artikel",
    component: _f5653b4e,
    name: "religi-artikel"
  }, {
    path: "/religi/quran-digital",
    component: _7a14501a,
    name: "religi-quran-digital"
  }, {
    path: "/suarapenentu/iframe",
    component: _f91654f2,
    name: "suarapenentu-iframe"
  }, {
    path: "/suarapenentu/quick-count",
    component: _1b736648,
    name: "suarapenentu-quick-count"
  }, {
    path: "/suarapenentu/rekamjejak",
    component: _2d61141b,
    name: "suarapenentu-rekamjejak"
  }, {
    path: "/suarapenentu/widget",
    component: _1e87dd27,
    name: "suarapenentu-widget"
  }, {
    path: "/video/terpopuler",
    component: _4f7be54f,
    name: "video-terpopuler"
  }, {
    path: "/auth/register/with-email",
    component: _2d3bf701,
    name: "auth-register-with-email"
  }, {
    path: "/academy/complete/:slug?",
    component: _264da91e,
    name: "academy-complete-slug"
  }, {
    path: "/academy/detail/:slug?",
    component: _e5c88294,
    name: "academy-detail-slug"
  }, {
    path: "/academy/karya-academy/:slug?",
    component: _609ac004,
    name: "academy-karya-academy-slug"
  }, {
    path: "/academy/mentor/:slug?",
    component: _e16d43b4,
    name: "academy-mentor-slug"
  }, {
    path: "/event/linimasa-event-narasi/:slug?",
    component: _1248a066,
    name: "event-linimasa-event-narasi-slug"
  }, {
    path: "/religi/jadwal-sholat/:slug?",
    component: _9ee8c43c,
    name: "religi-jadwal-sholat-slug"
  }, {
    path: "/religi/quran-digital/:slug",
    component: _dfd65e24,
    name: "religi-quran-digital-slug"
  }, {
    path: "/suarapenentu/rekamjejak/:slug",
    component: _2baa72d3,
    name: "suarapenentu-rekamjejak-slug"
  }, {
    path: "/read/advertorial/:channel?/:slug?",
    component: _16d5acd3,
    name: "read-advertorial-channel-slug"
  }, {
    path: "/religi/quran-digital/:slug?/:idAyat",
    component: _37cd08e1,
    name: "religi-quran-digital-slug-idAyat"
  }, {
    path: "/video/advertorial/:channel?/:slug?",
    component: _5ab7984c,
    name: "video-advertorial-channel-slug"
  }, {
    path: "/academy/:slug",
    component: _d74390e0,
    name: "academy-slug"
  }, {
    path: "/event/:organizer",
    component: _4df34e6f,
    name: "event-organizer"
  }, {
    path: "/invite/:token?",
    component: _4d575f29,
    name: "invite-token"
  }, {
    path: "/karir/:slug",
    component: _d5899cae,
    name: "karir-slug"
  }, {
    path: "/live/:slug",
    component: _5a46ef8e,
    name: "live-slug"
  }, {
    path: "/polling/:slug?",
    component: _162daba7,
    name: "polling-slug"
  }, {
    path: "/program/:childBrand",
    component: _24a3770b,
    name: "program-childBrand"
  }, {
    path: "/read/:slug?",
    component: _96136890,
    name: "read-slug"
  }, {
    path: "/tags/:slug?",
    component: _b393c30a,
    name: "tags-slug"
  }, {
    path: "/event/:organizer/:slug",
    component: _2fb30b2c,
    name: "event-organizer-slug"
  }, {
    path: "/playlist/:category?/:channel",
    component: _51de9d3f,
    name: "playlist-category-channel"
  }, {
    path: "/playlist/:category?/:slug?",
    component: _8c41847c,
    name: "playlist-category-slug"
  }, {
    path: "/podcast/:channel?/:slug?",
    component: _08b25ed1,
    name: "podcast-channel-slug"
  }, {
    path: "/program/:childBrand/:slugProgram",
    component: _0818c51e,
    name: "program-childBrand-slugProgram"
  }, {
    path: "/read/:channel?/:slug?",
    component: _584242c1,
    name: "read-channel-slug"
  }, {
    path: "/video/:channel?/:slug?",
    component: _10d6dbba,
    name: "video-channel-slug"
  }, {
    path: "/event/:organizer/:slug/checkout",
    component: _47b4d644,
    name: "event-organizer-slug-checkout"
  }, {
    path: "/event/:organizer/:slug/survey",
    component: _6ad6761c,
    name: "event-organizer-slug-survey"
  }, {
    path: "/event/:organizer/:slug/ticket",
    component: _6f4c5fa4,
    name: "event-organizer-slug-ticket"
  }, {
    path: "/playlist/:category?/:channel?/:slug",
    component: _5027fbf7,
    name: "playlist-category-channel-slug"
  }, {
    path: "/",
    component: _1d4b0d3b,
    name: "index"
  }, {
    path: "/:category",
    component: _418b9ec9,
    name: "category"
  }, {
    path: "/:slug",
    component: _1b946bf3,
    name: "slug"
  }, {
    path: "/:category/:slug",
    component: _3fd4fd81,
    name: "category-slug"
  }, {
    path: "/:category/:slug.vue",
    component: _3c03b75c,
    name: "category-slug.vue"
  }, {
    path: "/:channel/:slug?",
    component: _03159634,
    name: "channel-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
